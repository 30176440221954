/// <reference path="../../typings/graphql.d.ts"/>

import React, { useState } from 'react'

import { Page, List, LinkItem, Item, SSR_Item, Fader, MovieVM, ItemTagHighlight, movieToMeta, Row } from '../components/utils'
import Layout, { extractData, PageGraphQlData, THEMES } from '../components/layout'
import { Movie, Days, Movies, Day, Times, Model, CinemaId, ImageLink, Shows, Show, Cinema, CinemasMap } from '../model/model'
import styled, { ThemeProvider } from 'styled-components'
import { Moment } from 'moment'
import * as R from 'ramda'

import MovieList from '../components/movie-list'
import Section from '../components/section';
import MovieSummary, { createSubtitle } from '../components/movie-summary';
import { gqlToMovie, parseNowShowingText, patchInPlace } from '../model/gql-paser';
import { Dispatch } from 'redux';
import { Action, Actions } from '../model/actions';
import { connect, Provider } from 'react-redux';
import createStore, { FLAGS } from '../model/create-store';
import { Trailer } from '../components/movie-trailer';
import { Tracking } from '../model/utils';
import { NewsletterForm } from '../components/newsletter-form';
import { IconFollow } from '../components/icon-follow';
import { WhatsAppRegistrationButton } from '../components/whatsapp-reg-button';

const CenteredRow = styled(Row)`
align-items: center;
justify-content: center;
`

const MovieTitle = styled.h1`
  font-size: 1.4rem;
  color: ${props => props.theme.colors.highlight};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0.2rem;
  width: 90%;
  align-self: center;
`

const MovieSubtitle = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme.colors.attenuate};
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
`

const ShowsContainer = styled.div`
`

const Showtimes = styled(List)`
  position: relative;
`

interface PageIntents {
  onSelectDay: (day: Day) => void
  onSelectCinema: (cinemaId: CinemaId) => void
}

interface StaticProps {
  movie: Movie
  cinemas: Cinema[]
  banner: ImageLink | null
  topMargin: number
  backgroundImage: string | null
}

interface DynamicProps {
  selectedDay: Day | null
  now: Moment
  nowShowing: Movie[]
  comingSoon: Movie[]
  events: Movie[]
  cinemaShows: { 'mds': Show[]; 'odeon6': Show[]; }
  displayDays: Day[]
  selectedCinema: CinemaId | null
}

const RelativePage = styled(Page)`
  position: relative;
`

const ReleaseDate = styled(Item)`
  width: fit-content;
  align-self: center;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
  text-align: center;
`

type PageProps = StaticProps & DynamicProps

interface MovieShowsProps {
  movie: Movie
  cinemas: Cinema[]
  cinemaShows: CinemasMap<Show[]>
  selectedCinema: CinemaId | null
  days: Day[]
  selectedDay: Day | null
  now: Moment
  onSelectDay: (day: Day) => void
  onSelectCinema: (cinema: CinemaId) => void
}

class MovieShows extends React.Component<MovieShowsProps, {isSSR: boolean}> {

  constructor(props) {
    super(props)
    this.state = {isSSR: true}
  }

  componentDidMount() {
    this.setState({isSSR: false})
  }

  getCinemaItemState(cinema: CinemaId, selectedCinema: CinemaId | null, hasCinemaShows: boolean) {
    return hasCinemaShows === false
      ? 'disabled'
      : cinema === selectedCinema
        ? 'selected'
        : 'normal'
  }

  getCinemaDayItemState(cinema: CinemaId | null, day: Day, m: Movie, selectedDay: Day | null) {
    return R.none(s => s.cinemaId === cinema, m.shows[Days.toString(day)] || [])
      ? 'disabled'
      : selectedDay != null  && Days.isEqual(day)(selectedDay)
        ? 'selected'
        : 'normal'
  }

  render () {
    const {now, movie, cinemas, cinemaShows, selectedCinema, days, selectedDay, onSelectCinema, onSelectDay} = this.props
    const {isSSR} = this.state
    const isAvailableShow = Shows.isAfter20Mins(now)
    const today = Days.fromMoment(now)

    return (
      <ShowsContainer>
        <List wrap='nowrap'>
        { cinemas.map (c =>
          isSSR
          ? <SSR_Item key={'SSR' + c.id}>{c.name}</SSR_Item>
          : <LinkItem key={c.id}
              state={this.getCinemaItemState(c.id, selectedCinema, cinemaShows[c.id].length > 0)}
              onClick={(e) => {
                Tracking.trackEngagement('movie/select-cinema', c.id, movie.title)
                onSelectCinema(c.id)}
              }>
              {c.name}
            </LinkItem>
        )}
        </List>
        <List wrap='nowrap'>
          {
              days.map(day => (
                isSSR
                ? <SSR_Item key={'SSR' + Days.toString(day)} >{ Days.toPrettyString(day, Days.fromMoment(now)) }</SSR_Item>
                : <LinkItem key={Days.toString(day)}
                            state={this.getCinemaDayItemState(selectedCinema, day, movie, selectedDay)}
                            onClick={(e) => {
                              Tracking.trackEngagement('movie/select-day', Days.difference(day, today).toString(), movie.title)
                              onSelectDay(day)
                            }}>
                    { Days.toPrettyString(day, Days.fromMoment(now)) }
                  </LinkItem>
              ))
          }
        </List>
        { selectedDay != null &&
          <Fader key={selectedCinema + Days.toString(selectedDay)}>
            <Showtimes wrap='wrap'>
                {
                  (movie.shows[Days.toString(selectedDay)] || [])
                    .filter ( show => show.cinemaId === selectedCinema)
                    .map( show =>
                      isSSR
                      ? <SSR_Item key={'SSR' + Days.toString(show.day) + '@' + Times.toString(show.time)}>
                          {Times.toString(show.time)}
                        </SSR_Item>
                      : <Item key={Days.toString(show.day) + '@' + Times.toString(show.time)} state={ isAvailableShow(show) ? 'normal' : 'disabled'}>
                          {Times.toString(show.time)}
                          { show.tags
                          ? <ItemTagHighlight state={ isAvailableShow(show) ? 'normal' : 'disabled'}>{show.tags[0]}</ItemTagHighlight>
                          : null
                          }
                        </Item>
                    )
                }
              </Showtimes>
            </Fader>
        }
      </ShowsContainer>
    )
  }
}

const TrailerContainer = styled.div`
  margin-bottom: 15px;
  position: relative;
`

const VMWarning = styled.span`
  color: red;
  &::before {
    color: ${props => props.theme.colors.attenuate};
    content: ' | ';
  }
`


const ReleaseSection: React.SFC<{movie:Movie, cinemaId: CinemaId}> = ({movie, cinemaId}) => {
  const ReleaseDecorator = RELEASE_DECORATORS[FLAGS.newsletter(cinemaId)]
  const releaseElement = (
    <ReleaseDate as='div' state='normal'>
      { MovieVM.getReleaseMessage(movie, cinemaId) }
    </ReleaseDate>
  )
  return ReleaseDecorator != null
  ? <ReleaseDecorator movie={movie} cinemaId={cinemaId}>
      {releaseElement}
    </ReleaseDecorator>
  : releaseElement
}


const getWhatsAppMessage = (movie: Movie): string => (
  `Ciao, vorrei iscrivermi al servizio WhatsApp del Multiplex delle Stelle e ricevere aggiornamenti sulla programmazione. Non vedo l'ora esca ${movie.title}! ;)`
)

const WhatsappMessageReleaseDecorator: React.SFC<{movie:Movie, cinemaId: CinemaId}> = ({movie, cinemaId, children}) => {
  return (
    <>
      <CenteredRow>
        {children}
      </CenteredRow>
      <WhatsAppRegistrationButton
          whatsappMessage={getWhatsAppMessage(movie)}
          onClick={ () => Tracking.trackEngagement('movie/subscribe-to-whatsapp', 'track-release', movie.title) }>
        Iscriviti al nostro servizio gratuito WhatsApp per sapere quando il film sarà in programmazione
      </WhatsAppRegistrationButton>
    </>
  )
}

const NewsletterReleaseDecorator: React.SFC<{movie:Movie, cinemaId: CinemaId}> = ({movie, cinemaId, children}) => {
  const [state, update] = useState<'not-subscribed' | 'subscribing' | 'subscribed' | 'error'>('not-subscribed')
  return (
    <>
      <CenteredRow>
        {children}
        <IconFollow state={state === 'subscribing' || state === 'subscribed'
            ? 'highlight'
            : 'default'
          }
          onClick={() =>
            update(state === 'not-subscribed' ? 'subscribing' : 'not-subscribed')
          }/>
      </CenteredRow>
      { state !== 'not-subscribed'
      ? <Fader>
          <NewsletterForm formName='track-release' cinemaId={cinemaId} movie={movie}
            text="Registrati e ti avviseremo quando il film sarà in programmazione"
            buttonText="Avvisami!"
            tags={[]}
            onSuccess={(email, formName) => {
              Tracking.trackEngagement('movie/subscribe-to-newsletter', formName, movie.title)
              update('subscribed')
            }}
            onError={() => update('error')}
            />
        </Fader>
      : null
      }
    </>
  )
}

const RELEASE_DECORATORS: {[key: string]: React.SFC<{movie:Movie, cinemaId: CinemaId}>} = {
  'newsletter': NewsletterReleaseDecorator,
  'whatsapp': WhatsappMessageReleaseDecorator
}

const MoviePage: React.SFC<PageProps & PageIntents> = ({ now, cinemas, cinemaShows, displayDays, movie, selectedDay, selectedCinema, nowShowing, comingSoon, events, onSelectDay, onSelectCinema }) => {
  const today = Days.fromMoment(now)
  return (
  <Layout showBackButton={true} cinemas={cinemas} title={movie.title} meta={movieToMeta(cinemas[0], movie)}>
    <RelativePage>
      <MovieTitle>{movie.title}</MovieTitle>
      <MovieSubtitle>
        {createSubtitle(movie)}
        {
          movie.isVM18
          ? <VMWarning>VM18</VMWarning>
          : movie.isVM14
            ? <VMWarning>VM14</VMWarning>
            : null
        }
      </MovieSubtitle>
      {
        movie.shows == null || R.keys(movie.shows).length === 0
        ? <ReleaseSection movie={movie} cinemaId={cinemas[0].id}/>
        : <MovieShows cinemas={cinemas} now={now} movie={movie} days={displayDays} cinemaShows={cinemaShows}
            selectedDay={selectedDay} selectedCinema={selectedCinema}
            onSelectCinema={onSelectCinema} onSelectDay={onSelectDay}/>
      }
      <TrailerContainer>
        <Trailer movie={movie}/>
      </TrailerContainer>

      <MovieSummary mainCinemaId={cinemas[0].id} movie={movie} includeTitle={false}/>
      { nowShowing.length > 0 &&
        <Section title="In Programmazione" linkTo="/">
          <MovieList  look='slider1' lazy={true}
            items={MovieVM.computeMovieListForNowShowing(cinemas, nowShowing, today)}
            onCardClick={(item, idx) => {Tracking.trackNavigation('movie/now-showing-list', item.movie.title, idx.toString())}}/>
        </Section>
      }
      { events.length > 0 &&
        <Section title="Eventi" linkTo="/eventi">
          <MovieList look='slider2' lazy={true}
            items={MovieVM.computeMovieListForEvents(cinemas, events, today)}
            onCardClick={(item, idx) => {Tracking.trackNavigation('movie/events-list', item.movie.title, idx.toString())}}/>
        </Section>
      }
      { comingSoon.length > 0 &&
        <Section title="Prossimamente" linkTo="/prossimamente">
          <MovieList look='grid' lazy={true}
            items={MovieVM.computeMovieListForComingSoon(cinemas, comingSoon, today)}
            onCardClick={(item, idx) => {Tracking.trackNavigation('movie/coming-soon-list', item.movie.title, idx.toString())}}/>
        </Section>
      }
    </RelativePage>
  </Layout>
)}

const mapModelToProps: (model: Model, ownProps: StaticProps) => PageProps = (model, ownProps) => {
  if (model.page.id !== 'movie') {
    throw new Error('Unexpected state when computing Movie props: ' + model.page.id)
  }
  const today = Days.fromMoment(model.now)
  const activeDays = Movies.getPresentAndFutureDays(Days.fromMoment(model.now), [ownProps.movie])
  const displayDays: Day[] = R.any(Days.isEqual(today), activeDays)
    ? activeDays
    : [today, ...activeDays]

  return {
    cinemas: ownProps.cinemas,
    comingSoon: model.comingSoon,
    nowShowing: model.nowShowing,
    events: model.events,
    movie: ownProps.movie,
    banner: ownProps.banner,
    topMargin: ownProps.topMargin,
    backgroundImage: ownProps.backgroundImage,
    now: model.now,
    selectedDay: model.page.selectedDay,
    selectedCinema: model.page.selectedCinema,
    cinemaShows: model.page.cinemaShows,
    displayDays: R.sortBy(Days.toMilliseconds, displayDays),
  }
}

const mapDispatchToIntents = (dispatch: Dispatch<Action>) => ({
  onSelectDay: (day: Day) => {
    dispatch(Actions.selectDay(day))
  },
  onSelectCinema: (cinemaId: CinemaId) => {
    dispatch(Actions.selectCinema(cinemaId))
  }
})

const ConnectedPage = connect(
  mapModelToProps,
  mapDispatchToIntents
)(MoviePage as any)

interface GatsbyMovieTemplateProps {
  pageContext: {
    movie: GQL.IMovie,
    query: PageGraphQlData,
    c1nowShowingRaw: string,
    c2nowShowingRaw: string
  }
}


const GatsbyMovieTemplate: React.SFC<GatsbyMovieTemplateProps> = ({ pageContext: { query, movie } }) => {
  const m = gqlToMovie()(movie)
  const { cinemas, mainCinemaId } = extractData(query)
  const c0movies = parseNowShowingText(cinemas[0].id, cinemas[0].nowShowingRaw)
  const c1movies = parseNowShowingText(cinemas[1].id, cinemas[1].nowShowingRaw)
  patchInPlace([m], c0movies.shows)
  patchInPlace([m], c1movies.shows, true)
  const { banner, topMargin, backgroundImage } = cinemas[0]
  const store = createStore('movie', cinemas, mainCinemaId, true, m)

  return (
    <ThemeProvider theme={THEMES[mainCinemaId]}>
      <Provider store={store}>
        <ConnectedPage movie={m} cinemas={cinemas}
          banner={banner} topMargin={topMargin} backgroundImage={backgroundImage}/>
      </Provider>
    </ThemeProvider>
  )
}

export default GatsbyMovieTemplate
