import React from 'react'
import styled from 'styled-components'
import { Column, Row } from './utils';
import { Tracking } from '../model/utils';
import { Movie } from '../model/model';


const Whatsapp = styled(Column)`
display: flex;
padding: 5px;
align-items: center;
user-select: none;

width: calc(100% + 10px);
margin: 10px -5px;
@media(min-width: 700px) {
  width: 600px;
  margin: 0 auto;
}

& > ${Row} {
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
`

const WhatsappRegistration = styled.a`
text-align: center;
font-weight: 100;
background-color: #333;
color: ${props => props.theme.colors.highlight};
text-transform: uppercase;
line-height: 1.3rem;
padding: 10px 5px;
margin-bottom: 0;

&:active {
  background-color: #555;
}

&:hover {
  background-color: #444;
}
`

export const WhatsAppRegistrationButton: React.SFC<{whatsappMessage: string, onClick: () => void}> = ({whatsappMessage, onClick, children}) => (
  <Whatsapp>
    <WhatsappRegistration href={'https://wa.me/393341539100?text=' + whatsappMessage} onClick={onClick}>
      {children}
    </WhatsappRegistration>
  </Whatsapp>
)