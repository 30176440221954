import React from 'react'
import styled from 'styled-components';

interface IconState {
  state: 'default' | 'highlight'
}
const IconSvg = styled.svg<IconState>`
  cursor: pointer;
  stroke: ${props => props.state === 'highlight'
    ? props.theme.colors.highlight
    :'white'
  };
  fill:  ${props => props.state === 'highlight'
    ? props.theme.colors.highlight
    :'white'
  };
  &:hover {
    stroke: ${props => props.theme.colors.highlight};
    fill: ${props => props.theme.colors.highlight};
  }
`

export const IconFollow: React.SFC<IconState & {onClick: () => void}> = ({state, onClick}) => {
  return (
    <IconSvg state={state} onClick={onClick} width="30px" height="30px" viewBox="0 -134 512 511" xmlns="http://www.w3.org/2000/svg">
      <path d="m161.777344 0v259.710938h350.222656v-259.710938zm175.113281 153.707031-123.867187-123.707031h247.730468zm-66.335937-23.851562-78.777344 78.675781v-157.351562zm21.226562 21.199219 45.109375 45.050781 45.105469-45.050781 78.757812 78.65625h-247.730468zm111.441406-21.199219 78.777344-78.675781v157.351562zm0 0"/>
      <path d="m0 114.855469h131.777344v30h-131.777344zm0 0"/>
      <path d="m45.589844 53.589844h86.1875v30h-86.1875zm0 0"/>
      <path d="m45.589844 176.121094h86.1875v30h-86.1875zm0 0"/>
    </IconSvg>
  )
}

