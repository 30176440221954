/// <reference path="../../typings/index.d.ts"/>

import React from 'react'
import { Link } from 'gatsby'

import { MESSAGE_MOVIE_SLUGS, Movie } from '../model/model'
import styled from 'styled-components'
import sliderBG from '../images/slider-bg-pattern.png'
import { Routing, posterWidthAndHeight } from './utils';
import { TaggedPoster } from './tagged-poster';
import RImage from './image';

export interface ListProps {
  look: 'grid' | 'slider1' | 'slider2'
}
const List = styled.ul<ListProps>`
  display: flex;
  flex-direction: row;
  ${props => props.look === 'grid'
    ? 'flex-wrap: wrap; \
       justify-content: space-around;'
    : 'overflow-x: scroll; \
       overflow-y: hidden; \
       background: url(' + sliderBG + '), ' +
        'linear-gradient( ' + props.theme.colors[props.look] + ', ' + props.theme.colors[props.look] + ' 50%, transparent 50%);'}
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-snap-stop: normal;
  list-style: none;
  margin: 0;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  background-repeat: repeat-x;
  background-attachment: local;
  background-size: 8px, 100%;
  background-position-y: 30px;
  @media(min-width: 700px) {
    background-size: unset;
    background-position-y: 50px;
  }
`

const MovieItem = styled.li<ListProps>`
  display: flex;
  scroll-snap-align: center;
  flex-shrink: 0;
  flex-grow: 0;
  flex-direction: column;
  ${props => props.look === 'grid'
    ? 'margin: 0 15px 15px 0;'
    : 'margin: 0 15px 0 0;'
  }
`

const CardLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex-grow: 0;
`

const PosterItem = styled(RImage)`
  display: block;
  background-color: rgba(3,3,3,0.3);
  ${posterWidthAndHeight(127)}
  object-fit: cover;
  margin: 0 10px 10px 10px;
  @media(min-width: 700px) {
    ${posterWidthAndHeight(177)}
  }
`

const MovieTitle = styled.span`
  text-align: center;
  width: 147px;
  flex-grow: 0;
  flex-wrap: wrap;
  font-size: 0.8rem;
  line-height: 1.2rem;
  @media(min-width: 700px) {
    font-size: 1rem;
    width: 197px;
  }
`
// to be honest I went in a roundabout way here
// because I already had the SVG and couldn't be bothered
// to recreate it
// hence there is a lot of "inversion"
const RightControl = styled.div`
  display: none;
  pointer-events: auto;
  position: absolute;
  height: 100%;
  width: 100px;
  right: 0;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  background: transparent;
  color: transparent;
  stroke: transparent;
  &:hover {
    background: linear-gradient(-90deg, rgba(18, 18, 18, 1), rgba(18, 18, 18, 0));
    color: white;
    stroke: white;
  }
  @media(min-width: 700px) {
    display: flex;
  }
`

const LeftControl = styled(RightControl)`
  left: 0;
  right: unset;
  transform: rotate(180deg);
`

const Controls = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
`

export interface ListItem {
  movie: Movie
  tags: string[]
}

interface MovieListProps {
  items: ListItem[]
  lazy: boolean
  look: 'grid' | 'slider1' | 'slider2'
  onCardClick: (item: ListItem, idx: number) => void
}

class MovieList extends React.Component<MovieListProps, {isClient: boolean}>{

  container: Element

  constructor(props) {
    super(props)
    this.state = {isClient: false}
  }

  setContainerRef(element) {
    this.container = element;
  }

  scroll(delta: number) {
    if (this.container.scrollBy) {
      this.container.scrollBy({
        left: delta,
        behavior: 'smooth'
      })
    } else {
      // IE11
      this.container.scrollLeft = this.container.scrollLeft + delta
    }
  }

  scrollForward() {
    this.scroll(this.container.clientWidth - 150)
  }

  scrollBackward() {
    this.scroll(-(this.container.clientWidth - 150))
  }

  componentDidMount() {
    this.setState({isClient: true})
  }

  render() {
    const { items, look, lazy, onCardClick } = this.props
    return (
      <div style={{position: 'relative'}}>
        <List look={look} ref={this.setContainerRef.bind(this)}>
          { items.filter(i => !MESSAGE_MOVIE_SLUGS.includes(i.movie.slug)).map( (item, idx) => (
              <MovieItem key={item.movie.id} look={look}>
                <CardLink to={Routing.toMoviePage(item.movie)}
                  onClick={() => {onCardClick(item, idx)}}>
                  { this.state.isClient
                  ? <TaggedPoster tags={item.tags}>
                      <PosterItem mode={lazy ? 'lazy' : 'eager'} image={item.movie.poster} alt={`Poster for ${item.movie.title}`}
                        sizes={[{viewport: 700, size: 127}]}
                        maxSize={177} />
                    </TaggedPoster>
                  : <PosterItem mode='stub' image={item.movie.poster} alt={`Poster for ${item.movie.title}`}
                      sizes={[{viewport: 700, size: 127}]}
                      maxSize={177} />
                  }
                  <MovieTitle>{item.movie.title}</MovieTitle>
                </CardLink>
              </MovieItem>
            ))
          }
        </List>
        { look === 'grid'
        ? null
        : <Controls>
            <LeftControl onClick={this.scrollBackward.bind(this)}>
              <svg width="22" height="89" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 1l18 43.5L2 88"  strokeWidth="2" fill="none" fillRule="evenodd"/>
              </svg>
            </LeftControl>
            <RightControl onClick={this.scrollForward.bind(this)}>
              <svg width="22" height="89" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 1l18 43.5L2 88"  strokeWidth="2" fill="none" fillRule="evenodd"/>
              </svg>
            </RightControl>
          </Controls>
        }
      </div>
    )
  }
}

export default MovieList
