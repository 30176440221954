import React, { useState, useRef } from 'react'
import { EmbedContainer, fade } from "./utils";
import { Movie, Movies } from "../model/model";
import YouTube from 'react-youtube';
import styled from 'styled-components';
import RImage from './image';
import bgPattern from '../images/bg_pattern.png'
import { isEmpty } from '../model/utils';

interface TrailerProps {
  movie: Movie
}

const TrailerPlaceholder = styled.div<{captureEvents: boolean}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.8);
  pointer-events: ${props => props.captureEvents
    ? 'auto'
    : 'none'
  };
  cursor: pointer;
  animation: ${fade} 0.2s linear;
`

const PlayButtonSVG = styled.svg<{animate: boolean}>`
position: absolute;
width: 20vw;
max-width: 100px;
cursor: pointer;
${ props => props.animate
  ? 'animation:  fade 2s cubic-bezier(.49,.05,.32,1.04) infinite alternate;'
  : ''
}
& circle {
  cursor: pointer;
  transform-origin: 50% 50%;
  pointer-events: none;
  stroke-width: 0;
  fill: rgba(18, 18, 18, 0.5);
}
& polygon {
  stroke-width: 1px;
  stroke: #FFFFFF;
  fill: #FFFFFF;
}
@keyframes fade {
  50% {
    opacity: 0.3;
  }
}
`
const TrailerCover = styled(RImage)`
pointer-events: none;
object-fit: cover;
`

const TrailerContainer = styled(EmbedContainer)`
  background: radial-gradient(rgba(18, 18, 18, 0), rgba(18, 18, 18, 0) 60%, rgba(18, 18, 18, 1)), url(${bgPattern});
`

export const PlayButton = ({animate}) => (
  <PlayButtonSVG animate={animate} viewBox="0 0 60 60" version="1.1">
    <circle stroke="#979797" cx="30" cy="30" r="27"></circle>
    <polygon stroke="#979797" points="24 18 24 43 43 30.5"></polygon>
  </PlayButtonSVG>
)

const ClickMask = styled.div<{side: 'top' | 'bottom'}>`
  position: absolute;
  ${props => props.side === 'top' ? 'top: 0;' : ''}
  ${props => props.side === 'bottom' ? 'bottom: 0;' : ''}
  width: 100%;
  height: 20%;
  pointer-events: auto;
  cursor: default;
`
type PlayerState = 'unstarted' | 'buffering' | 'play' | 'ready'

// We need this because browsers will block us from autoplaying
// the video if we haven't already interacted with the player first
const playerCanBeControlled = (playerState: PlayerState): boolean => (
  playerState !== 'unstarted'
)

const exitFullscreen = () => {
  if ((document as any).fullscreenElement) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
}

export const Trailer: React.SFC<TrailerProps> = ({movie}) => {
  const [playerState, setPlayerState] = useState('unstarted' as PlayerState)
  const playerRef = useRef(null)
  return isEmpty(movie.trailerUrl) && movie.picture == null
  ? null
  : (
    <TrailerContainer>
      { isEmpty(movie.trailerUrl)
      ? null
      : <YouTube videoId={Movies.getTrailerYoutubeID(movie.trailerUrl)}
          onReady={(e) => {
            playerRef.current = e.target
          }}
          onStateChange={(e) => {
            const newState = e.data
            switch (newState) {
              case -1: //(unstarted)
                break;
              case 0: //(ended)
                setPlayerState('ready')
                exitFullscreen()
                e.target.pauseVideo()
                e.target.seekTo(0)
                break;
              case 1: //(playing)
                setPlayerState('play')
                break;
              case 2: //(paused)
                break;
              case 3: //(buffering)
                setPlayerState('buffering')
                break;
              case 5: //(video cued)
                break;
            }
          }}
        />
      }
      { movie.picture == null || playerState === 'play'
      ? null
      : <TrailerPlaceholder captureEvents={playerCanBeControlled(playerState)}
          onClick={() => {
            playerRef.current && playerRef.current!.playVideo()
            // should happen anyways because players goes in play mode
            // but adding this safety in case the video doesn't start
            // at least we will show the youtube player
            setPlayerState('play')
        }}>
          { playerCanBeControlled(playerState)
          ? null
          : <>
              {/* This mask will prevent the user from clicking YouTube links
                  under the TrailerCover. It's only shown if the player can't be controlled
                  (otherwise we want the events to go to our TrailerPlaceholder)
                  and its job is to block the click events (also to avoid them
                  from triggering the TrailerPlaceholder).
                  Only top one is used because when the player is first rendered
                  there are no bottom controls (and after first play TrailerPlaceholder
                  will capture everything anyways) */}
              <ClickMask side='top' onClickCapture={(e) => {e.stopPropagation()}}/>
            </>
          }
          <TrailerCover mode='eager' alt={'Sfondo film ' + movie.title}
              image={movie.picture}
              sizes={[{viewport: 350, size: 350}, {viewport: 700, size: 700}]}
              maxSize={720} width="100%" height="100%"/>
          { isEmpty(movie.trailerUrl)
          ? null
          : <PlayButton animate={playerState === 'buffering'}/>
          }
        </TrailerPlaceholder>
      }
    </TrailerContainer>
  )
}
